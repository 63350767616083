import React, { Fragment } from 'react'
import styled from 'react-emotion'
import Facts from '../../components/Facts'
import Team from '../../components/Team'
import Testimonial from '../../components/Testimonial'
import { Four, One, Row, RowGroup, Three, Two } from '../../components/Grid'
import Image from '../../components/Image'
import OtherProjects from '../../components/OtherProjects'
import ProjectsContext from '../../components/ProjectsContext'
import Caption from '../../components/Caption'
import SEO from '../../components/SEO'
import { ProjectName } from '../../components/Style'
import ContactForm from '../../components/ContactForm'
import Layout from '../../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/projects",
      "name": "Projects"
    }
  },{
    "@type": "ListItem",
    "position": 2,
    "item": {
      "@id": "https://500tech.com/projects/phytech",
      "name": "Phytech"
    }
  }]
}`

export default () => {
  return (
    <Layout>
    <ProjectsContext.Consumer clientName="Phytech">
      {({ allProjects, currentProject }) => (
        <Fragment>
          <SEO
            path="/projects/phytech"
            title="Featured React project for Phytech by 500Tech"
            description="We’ve built a React application in the agriculture industry for Phytech."
            ld={breadcrumbs}
          />

          <ProjectName>{currentProject.projectName}</ProjectName>
          <Facts {...currentProject} />

          <RowGroup>
            <Row>
              <Three>
                <p>Phytech develops sensors for the agriculture.</p>

                <p>
                  We helped them build an application to help farmers increase
                  their yield by monitoring the fields online and making
                  informed farming decisions. We used React to built from
                  scratch a rich single-page application, which displays
                  real-time data from Phytech sensors installed on the fields.
                  We also migrated the native iOS and Android apps to React
                  Native.
                </p>

                <p>Watch the video of the application in action:</p>
              </Three>
            </Row>

            <Row>
              <Four>
                <MediaContainer
                  style={{
                    width: '100%',
                    paddingBottom: '73.91%',
                  }}>
                  <iframe
                    title="Video"
                    width="100%"
                    height="100%"
                    style={{ position: 'absolute' }}
                    src="https://player.vimeo.com/video/229041984"
                    frameBorder="0"
                    allowFullScreen
                  />
                </MediaContainer>
              </Four>
            </Row>
          </RowGroup>

          <Team team={currentProject.team} />

          <Testimonial />

          <RowGroup>
            <Row>
              <Three>
                <p>
                  The interactive map’s level of detail depends on its zoom
                  level:
                </p>
              </Three>
            </Row>

            <Row>
              <Two>
                <Image path="projects/phytech/phytech-map.jpg" />
                <Caption>Color-coded health of the plots</Caption>
              </Two>
              <Two>
                <Image
                  innerShadow
                  path="projects/phytech/phytech-map-detail.png"
                />
                <Caption>This plot is being irrigated now</Caption>
              </Two>
            </Row>
          </RowGroup>

          <Row>
            <One>
              Scrolling at the bottom bar reveals charts that show detailed
              values for a specific timeframe in a selected area.
            </One>
            <Three>
              <Image innerShadow path="projects/phytech/phytech-graph.jpg" />
            </Three>
          </Row>

          <RowGroup>
            <Row>
              <Three>
                <p>
                  Many elements have additional data that appears when the user
                  interacts with them:
                </p>
              </Three>
            </Row>

            <Row>
              <Two>
                <Image innerShadow path="projects/phytech/phytech-6.jpg" />
                <Caption>
                  Hovering over a day shows detailed precipitation and
                  irrigation
                </Caption>
              </Two>
              <Two>
                <Image innerShadow path="projects/phytech/phytech-5.jpg" />
                <Caption>
                  If a sensor is intalled, even the fruit size is available
                </Caption>
              </Two>
            </Row>
          </RowGroup>

          <Row>
            <Three>
              <Image path="projects/phytech/phytech-report2.jpg" />
            </Three>
            <One>
              The farmers construct custom reports with the flexible tables and
              charts. Create, rename, and arrange tabs with different subsets of
              data.
            </One>
          </Row>

          <Row>
            <One>
              The integrated notifications connect the web and mobile
              applications.
            </One>

            <One>
              <Image path="projects/phytech/phytech-mobile.jpg" />
            </One>

            <One>
              <Image path="projects/phytech/phytech-notify.jpg" />
            </One>

            <One>
              A farmer in field can set a reminder on the phone to review a
              particular area later on a larger screen.
            </One>
          </Row>

          <Row>
            <Three>
              <p>
                The application displays thousands of DOM elements and data
                points. We need to load the data timely in the background and
                unload it when it’s no longer needed. All server communication
                is done through a web worker to reduce load on main thread and
                make the UI responsive.
              </p>
            </Three>
          </Row>

          <OtherProjects currentProject={currentProject} />
          <ContactForm />
        </Fragment>
      )}
    </ProjectsContext.Consumer>
    </Layout>
  )
}

const MediaContainer = styled.div`
  background-color: #333;
  background-size: cover;
  position: relative;
`
